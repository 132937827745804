import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

function AccordionElement({item, index, length}) {
    const [isOpen, setState] = useState(false);
    const handleClick = () => {
        setState(!isOpen);
    }
  return (
    <div
    onClick={() => handleClick()}
      className={` bg-slate-900 ${
        index === 0
          ? "rounded-t-md"
          : index === length - 1
          ? "rounded-b-md"
          : ""
      }`}
      
    >
      <div
        className={`flex p-2 lg:p-4 justify-between items-center text-xl `}
      >
        <h2 className="text-lg sm:text-xl md:text-2xl tracking-wide text-white basis-6/7 mx-2">
          {item.title}
        </h2>
        <ChevronDownIcon
          className={`self-center transition-transform duration-500 w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 ${
            isOpen
              ? "rotate-180 text-white"
              : " hover:text-white"
          }`}
        ></ChevronDownIcon>
      </div>
      <div
        className={`bg-white border-x border-b border-black text-justify transition-all duration-800 ease-in-out text-slate-900 mt-2 w-full text-lg sm:text-xl md:text-2xl ${
          isOpen
            ? "p-4 max-h-max overflow-auto"
            : "px-4 max-h-0 overflow-hidden border-hidden"
        } ${index === length - 1 ? "mb-16" : ""}`}
      >
        {item.description}
      </div>
    </div>
  );
}

export default AccordionElement;
