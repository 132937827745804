
import { forwardRef, useRef, useState } from "react";
import Form from "./Form";

const FormPage = forwardRef((props, ref) => {
  return (
    <>
      <div ref={ref} className="flex min-h-[120%] flex-1 bg-gradient-to-b from-emerald-400 via-white to-[#050A30] flex-col justify-center px-16 sm:px-2 py-4 lg:px-8">
        <div className="sm:mx-auto py-10 sm:w-full sm:max-w-sm">
          <h2 className=" text-center text-4xl uppercase font-bold leading-9 tracking-tight text-black">
            Connect with us
          </h2>
        </div>
        <div className="mb-20 sm:mx-auto sm:w-full sm:max-w-sm">
            <Form></Form>
        </div>
      </div>
    </>
  );
})

export default FormPage;
