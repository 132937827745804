import Brain from "../res/brain.svg"
function Intro() {
  return (
    <>
      <div
        className="py-16"
        style={{ minHeight: "90vh" }} //style={{background: "linear-gradient(90deg, #667eea 0%, #764ba2 100%)"}}
      >
        <div className="container flex flex-col items-center justify-center h-3/5 mx-auto px-6">
          <img src={Brain} alt="Brain" className="w-60 md:w-80 mb-8"/>
          <div className="flex justify-center">
            <h2 className="text-lg md:text-xl leading-7 md:leading-9 font-bold mb-2 mr-1 text-gray-950">THE</h2>
            <h2 className="text-4xl md:text-6xl font-bold mb-2 text-gray-950">KA</h2>
            <h2 className="text-4xl md:text-6xl font-bold mb-2 text-gray-700">LP</h2>
            <h2 className="text-4xl md:text-6xl font-bold first-letter:mb-2 text-gray-500">AT</h2>
            <h2 className="text-4xl md:text-6xl font-bold  mb-2 text-gray-300">RU</h2>
          </div>
          <h2 className="text-xl md:text-3xl uppercase font-bold text-center px-1 mb-2 text-gray-900">
            Know your child's innate talent
          </h2>
        </div>
      </div>
    </>
  );
}

export default Intro;
