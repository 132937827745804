import { useEffect, useRef } from 'react';
import Intro from './components/Intro';
import Navbar from './components/Navbar';
import Reviews from './components/Reviews';
import AboutUs from './components/AboutUs';
import FAQs from './components/FAQs';
import FormPage from './components/FormPage';

function App() {

  const videoRef = useRef(null) 
  const formRef = useRef(null) 
  const reviewsRef = useRef(null) 

  return (
    <>
    <Navbar refs={[videoRef, formRef, reviewsRef]} ></Navbar>
    <Intro className="App-header"></Intro>
    <AboutUs ref={videoRef}></AboutUs>
    <FormPage ref={formRef}></FormPage>
    <Reviews ref={reviewsRef}></Reviews>
    <FAQs></FAQs>
    </>
  );
}

export default App;
