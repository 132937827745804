function IntelligenceIconData() {
    return ( 
        [
            {
                outerClass: "w-14 md:w-16 rounded-xl h-15 mt-5 px-3 py-2 bg-gray-900 border-2 border-red-600",
                innerClass: "text-center min-w-full text-2xl md:text-3xl leading-9 tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 font-bold",
                content: "IQ",
                popOverText: "Intelligence Quotient(IQ) is a measure of cognitive intelligence, assessing an individual's ability to understand and reason with information, solve problems, and adapt to new situations.",
            },
            {
                outerClass: "w-14 md:w-16 rounded-xl h-fit mt-5 px-3 py-2 bg-gray-900 border-2 border-purple-600",
                innerClass: "text-center text-2xl md:text-3xl leading-9 tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 font-bold",
                content: "EQ",
                popOverText: "Emotional Quotient(EQ) measures an individual's capacity to recognize, understand, manage, and effectively use their emotions as well as the emotions of others.",
            },
            {
                outerClass: "w-14 md:w-16 rounded-xl h-fit mt-5 px-3 py-2 bg-gray-900 border-2 border-green-600",
                innerClass: "text-center text-2xl md:text-3xl  leading-9 tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-green-400 to-purple-700 font-bold",
                content: "SQ",
                popOverText: "Social Quotient(SQ) assesses an individual's ability to navigate social interactions, understand social dynamics, and communicate effectively.",
            },
            {
                outerClass: "w-14 md:w-16 rounded-xl h-fit mt-5 px-2 lg:px-0  py-2 bg-gray-900 border-2 border-yellow-600",
                innerClass: "text-center text-2xl md:text-3xl leading-9 tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-green-300 via-yellow-300 to-pink-300 font-bold",
                content: "AQ",
                popOverText: "Adversity Quotient(AQ) also called adaptability or resilience quotient, gauges an individual's ability to cope with and overcome adversity and challenges.",
            }
        ]
     );
}

export default IntelligenceIconData;