import { Canvas } from "@react-three/fiber";
import React, {useEffect, useState } from "react";
import { Model } from "./BrainModel";
import { OrbitControls } from "@react-three/drei";

export const Brain = () => {
  const canvasStyles = {
    opacity: 0.9,
    margin: "auto",
    height: '300px',
    width: '100%',
  };
  const [scale, setScale] = useState(4);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setScale(5);
      } else {
        setScale(4);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Call initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Canvas className="canvas" style={canvasStyles}>
    <ambientLight intensity={0.5}></ambientLight>
    <directionalLight position={[-2,5,2]} intensity={1}/>
      <Model scale={scale} position={[0,-3,0]}></Model>
      <OrbitControls enablePan={true} enableRotate={true} enableZoom={false} rotateSpeed={2.0} dampingFactor={0.05} minPolarAngle={Math.PI / 2}  maxPolarAngle={Math.PI / 2}/>
    </Canvas>
  );
};

export default Brain;
