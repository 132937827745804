import { useForm } from "react-hook-form";
import Reaptcha from "reaptcha";
import mail from "../res/mail.png";
import { useState, useRef, useCallback, useMemo, useEffect } from "react";
function Form() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const str = "a401f3e7e0c804e3f5fb0867f1705755";
  const [verified, setVerified] = useState(false);
  const captchaRef = useRef(null);
  const Submit = async (data) => {
    try {
        var form = document.getElementById('form')
        form.action = `https://formsubmit.co/${str}`;
        form.submit()
    } catch (error) {
        console.error(error);
    }
  };
//const [containerRef, isVisible] =  useElementOnScreen({threshold: 0.3});
const [isVisible, setIsVisible] = useState(false);
const handleIntersection = useCallback((entries) => {
  const [entry] = entries;
  setIsVisible(entry.isIntersecting);
}, [])
const ref = useRef(null);
useEffect(() => {
  const observer = new IntersectionObserver(handleIntersection, {threshold: 0.3});
  if (ref.current) {
    observer.observe(ref.current);
  }
  return () => {
    observer.unobserve(ref.current);
  };
}, []);
  return (
    <div ref={ref}>
        
    <form
      id="form"
      className={`space-y-6 px-10 py-5 border-2 rounded-lg transition duration-1000 ${isVisible ? "opacity-100 blur-none translate-x-0" : "opacity-0 blur-lg -translate-x-20"}  motion-reduce:transition-none motion-reduce:hover:transform-none`}
      onSubmit={handleSubmit(Submit)}
      method="POST"
    >
      <img
        className="mx-auto h-20 w-20 animate-upSlow"
        src={mail}
        alt="Email"
      />
      <input type="hidden" name="_captcha" value="false"/>
      <input type="hidden" name="_subject" value="Thanks for reaching out!"/>
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Full Name
        </label>
        <div className="mt-2">
          <input
            id="name"
            name="name"
            {...register("name", { required: "Name is required" })}
            placeholder="Enter your name"
            className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
          {errors.name && (
            <p className="mt-2 text-red-500">{errors.name.message}</p>
          )}
        </div>
      </div>

      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Email address
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /\b[\w-]+@[\w-]+\.\w{2,4}\b/gi,
                message: "Email is not valid",
              },
            })}
            className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
          {errors.email && (
            <p className="mt-2 text-red-500">{errors.email.message}</p>
          )}
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between">
          <label
            htmlFor="contact"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Contact
          </label>
        </div>
        <div className="mt-2">
          <input
            id="contact"
            name="contact"
            {...register("contact", {
              pattern: {
                value: /\b[1-9]{1}\d{2}[-.]?\d{3}[-.]?\d{4}\b/g,
                message: "Contact should be exactly 10 digits",
              },
            })}
            placeholder="Enter your contact"
            className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
          {errors.contact && (
            <p className="mt-2 text-red-500">{errors.contact.message}</p>
          )}
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Enquiry
          </label>
        </div>
        <div className="mt-2">
          <textarea
            id="enquiry"
            name="message"
            {...register("enquiry", {
              minLength: {
                value: 25,
                message: "Enter at least 25 characters.",
              },
            })}
            className="block px-2 w-full h-40 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
          {errors.enquiry && (
            <p className="mt-2 text-red-500">{errors.enquiry.message}</p>
          )}
        </div>
      </div>
      <div className="flex"></div>
     
      <div>
        {!verified ? (
          <button
            className="flex w-full justify-center rounded-md bg-gray-900 px-3 py-1.5 text-xl font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              captchaRef.current.execute();
            }}
          >
            Verify Captcha
          </button>
        ) : (
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-gray-900 px-3 py-1.5 text-xl font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Submit
          </button>
        )}
      </div>
      <div className="invisible">
      <Reaptcha
        ref={captchaRef}
        onVerify={(data) => {
          console.log('Data: ', data);
          setVerified(true);
        }}
        size="invisible"
        sitekey={process.env.REACT_APP_SITE_KEY}
      />
      </div>
    </form>
    </div>
  );
}

export default Form;
