import {
  ArrowDownCircleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import faq from "./faqIcon.svg";
import { useState } from "react";
import json from "../res/data.json";
import AccordionElement from "./AccordionElement";
const items = json.faqs;
function FAQs() {
  const [selectedFAQ, setSelectedFAQ] = useState(-1);
  const handleFAQClick = (index) => {
    if (selectedFAQ === index) setSelectedFAQ(-1);
    else {
      setSelectedFAQ(index);
    }
  };
  return (
    <div className="h-screen bg-white flex flex-nowrap justify-center">
      <div className="flex flex-col container mb-10 items-center mt-16">
        <div id="title" className="self-center w-fit mb-16">
          <h1 className="text-slate-900 border-t-8 pb-1 border-b-8 rounded-lg border-slate-800 w-full flex justify-center font-bold text-2xl sm:text-4xl md:text-5xl lg:text-6xl">
            Frequently Asked Questions
          </h1>
          <div className="flex mt-16 justify-center">
            <img
              src={faq}
              alt="faqIcon"
              className="flex justify-center max-w-[10rem]"
            ></img>
          </div>
        </div>
        <div
          id="accordion"
          className="divide-y-2 mb-10 w-[80vw] sm:w-[70vw] divide-gray-500"
        >
          {items.map((item, index) => (
            <div key={index} onClick={() => handleFAQClick(index)}>
              <AccordionElement
                item={item}
                index={index}
                length={items.length}
              ></AccordionElement>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQs;
