import { useState } from "react";
import PopOver from "@mui/material/Popover"
function IntelligenceIcon(props) {
    const [anchorEle, setAnchorEle] = useState(null);
    const handleMouseEnter = (event) => {
        setAnchorEle(event.currentTarget);
    }
    const open = Boolean(anchorEle);
    return ( 
    <div className={props.outerClass} onMouseEnter={handleMouseEnter} onMouseLeave={() => setAnchorEle(null)}>
    <h2 className={props.innerClass}>
      {props.children}
    </h2>
    <PopOver
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        slotProps={{
            paper : {
                sx: {
                    borderRadius: '15px',
                    backgroundColor: 'transparent',
                }
            }
        }}
        marginThreshold={20}
        open={open}
        anchorEl={anchorEle}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEle(null)}
        disableRestoreFocus
    >
        <div className="p-2 text-white w-[200px] rounded-2xl border-2 border-gray-500 bg-gray-900">
        <h1 className="mx-auto text-center font-bold text-white text-lg">{props.children}</h1>
        <div className="text-center">
            {props.popOverText}
        </div>
        </div>
        
    </PopOver>
  </div> );
}

export default IntelligenceIcon;