import { forwardRef } from "react";
import {
  ArrowDownCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import IntelligenceData from "./IntelligenceIconData";
import Brain from "./Brain";
import careerImg from "./careerCounselling.svg";
import careerGImg from "./careerGuidance.svg";
import lifeImg from "./lifetimeSupport.svg";
import persImg from "./personalityDev.svg";
import IntelligenceIcon from "./IntelligenceIcon";
const AboutUs = forwardRef((props, ref) => {
  const data = [
    {
      title: "DMIT Counselling",
      imgSrc: careerImg,
      description:
        "Personalized counselling from our top counselors through DMIT to reveal your unique strengths and potential.",
    },
    {
      title: "Career Guidance",
      imgSrc: careerGImg,
      description:
        "Navigate your career path confidently with tailored advice based on your natural abilities and interests.",
    },
    {
      title: "Personality Development",
      imgSrc: persImg,
      description:
        "Elevate your personal and professional life through targeted programs that enhance your skills, confidence, and charisma.",
    },
    {
      title: "Lifetime Support",
      imgSrc: lifeImg,
      description:
        "Enjoy continuous support and resources throughout your life's journey, ensuring you're always on the path to success.",
    },
  ];
  return (
    <div className="relative w-full min-h-screen bg-gradient-to-b from-white to-emerald-400">
      <div className="px-4 lg:px-14 py-6 w-full mx-auto md:w-[80%] rounded-3xl min-h-screen bg-gray-950 grid-cols-1 justify-items-center relative">
      <InformationCircleIcon className="w-14 h-14  mx-auto animate-pulse text-white"></InformationCircleIcon>
        <div className="flex my-2 sm:my-0 w-full">
          <h1
            ref={ref}
            id="heading"
            className="flex items-center justify-end text-3xl sm:text-4xl basis-1/2 font-extrabold leading-none tracking-tight md:text-5xl text-white"
          >
            What's
          </h1>
          <h1 className="ml-4 flex items-center text-3xl sm:text-4xl basis-1/2 font-extrabold leading-none tracking-tight md:text-5xl text-transparent bg-clip-text bg-gradient-to-l from-blue-400 to-emerald-400">
            DMIT
          </h1>
        </div>
        <Brain></Brain>
        
        <div className="rounded-xl mx-auto  mt-10 bg-gradient-to-r p-1 from-white via-gray-950 to-emerald-400">
          <div className="flex flex-col justify-between text-justify h-full bg-gray-950 rounded-lg p-4 text-gray-200">
            DMIT, or Dermatoglyphics Multiple Intelligence Test, is a scientific
            method used to analyze an individual's unique fingerprint patterns
            to gain insights into their multiple intelligences and potential
            capabilities. It is based on the idea that the patterns of ridges
            and creases on our fingertips are linked to our brain's development
            and functions. DMIT aims to assess various types of intelligence,
            such as logical-mathematical, linguistic, musical, interpersonal,
            intrapersonal, and more. By analyzing fingerprint patterns and
            correlating them with specific areas of intelligence, DMIT provides
            individuals with insights into their strengths, weaknesses, and
            potential areas for personal and educational development. It's often
            used in educational and career counseling to help individuals make
            informed decisions about their academic and professional paths by
            aligning their natural strengths with suitable fields of study or
            career choices.
          </div>
        </div>
        <div className="md:mb-5 px-2 py-5 flex text-lg text-justify font-normal text-gray-500 lg:text-3xl sm:px-16 xl:px-48 "></div>
        <div className="mt-10 py-2 flex flex-col gap-3">
          <p className="mb-4 text-xl sm:text-2xl md:text-3xl font-medium text-center text-white tracking-tight ">
            Capitalize on YOUR strongest type of intelligence to achieve your goals
          </p>
          <div className="w-[80%] mx-auto grid gap-10 justify-items-center grid-cols-4 mb-10">
          {IntelligenceData().map((item, index) => 
           ( <IntelligenceIcon key={index} popOverText={item.popOverText} outerClass={item.outerClass} innerClass={item.innerClass}>{item.content}</IntelligenceIcon>)
          )}
          </div>
        <h1 className="ml-4 text-center mt-6 mb-12 text-3xl sm:text-4xl basis-1/2 font-extrabold leading-none tracking-tight md:text-5xl text-white">
            What we offer:
          </h1>
          </div>
        <span className="w-[80%] mx-auto grid grid-col-1 sm:grid-cols-2 sm:grid-rows-2 gap-7 gap-x-10">
          {data.map((item, index) => (
            <span key={index} className="flex flex-col justify-center items-center p-2 rounded-xl outline outline-white">
              <h1 className="text-xl lg:text-2xl text-white font-bold">
                {item.title}
              </h1>
              <img className="w-32 h-32 my-4" src={item.imgSrc} alt={item.title}></img>
              <p className="text-md p-2 lg:text-lg text-justify text-white">
                {item.description}
              </p>
            </span>
          ))}
        </span>
        <h2 className="col-span-3 mt-10 text-center text-xl sm:text-3xl md:text-4xl leading-9 tracking-tight text-gray-200">
          Want to learn more about it from us?
        </h2>
        <div className="flex justify-center mt-8">
          <ArrowDownCircleIcon className="w-14 h-14 align-middle animate-pulse"></ArrowDownCircleIcon>
        </div>
      </div>
    </div>
  );
});

export default AboutUs;
